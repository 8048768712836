<template>
  <div class="with-nav-bar">
    <van-nav-bar
      left-text="返回"
      title="充电站详情"
      :right-text="!state.loading ? '更新' : ''"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
      @click-right="
        !state.loading &&
          $router.push({ name: 'StationForm', query: { id: state.id } })
      "
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <div v-if="state.images.length" class="bg-secondary text-center">
        <van-swipe
          indicator-color="white"
          :autoplay="3000"
          :height="180"
          lazy-render
        >
          <van-swipe-item
            v-for="(image, index) in state.images"
            :key="image"
            @click="previewImage(index)"
          >
            <van-image width="100%" height="100%" fit="cover" :src="image" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <van-cell title="所属区域" :value="state.area" />
      <van-cell title="详细地址" :value="state.address" />
      <van-cell title="站点名称" :value="state.name" />
      <van-cell title="车位数量" :value="state.parking_num" />
      <van-cell title="站点状态" :value="state.status" />
      <van-cell
        title="运营人员"
        :value="`${state.support_contact} / ${state.support_phone}`"
      />
      <van-cell title="服务电话" :value="state.service_phone" />
      <van-cell
        v-if="agent.level == 2"
        :to="{ name: 'StationFee', query: { station_id: state.id } }"
        is-link
      >
        <template #title> <span class="text-primary">费用设置</span> </template>
      </van-cell>
      <van-cell
        v-else
        :to="{ name: 'StationProfitSharing', query: { station_id: state.id } }"
        is-link
      >
        <template #title> <span class="text-primary">分润设置</span> </template>
      </van-cell>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  Field,
  Toast,
  Swipe,
  SwipeItem,
  Image as VanImage,
  ImagePreview,
} from "vant";
import { loadStation } from "@/api/station.service";
import { useStore } from "vuex";
import { stationStatus } from "@/constant/station";
export default {
  components: {
    [Field.name]: Field,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [VanImage.name]: VanImage,
    [ImagePreview.name]: ImagePreview,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const agent = store.getters.agentInfo;
    const state = reactive({
      loading: true,
      images: [], // 从 state.pictures 中提取而来
    });

    const loadForm = async (station_id) => {
      try {
        const { result } = await loadStation(station_id);
        for (const key in result) {
          state[key] = result[key];
        }

        // 处理图片显示, 注意: 数据里的URL是无协议头的
        const pictures = JSON.parse(state.pictures);
        if (pictures instanceof Array) {
          for (const i in pictures) {
            const url = `${window.location.protocol}${pictures[i]}`;
            state.images.push(url);
          }
        }

        stationStatus.map((item) => {
          if (item.id == result.status) {
            state.status = item.name;
          }
        });
        state.loading = false;
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const previewImage = (index) => {
      ImagePreview({
        images: state.images,
        startPosition: index,
      });
    };

    onMounted(() => {
      const { id } = route.query;
      if (!id) {
        Toast.fail("参数错误");
        router.back();
      } else {
        state.id = id;
        loadForm(id);
      }
    });

    return { agent, state, previewImage };
  },
};
</script>
